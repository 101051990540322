/*-----------------------------------------------------------------

-------------------------------------------------------------------
CSS TABLE OF CONTENTS
-------------------------------------------------------------------

01. abstracts
    1.01 --> mixins
    1.02 --> variable

02. base
    2.01 --> typography
    2.02 --> animation
    2.03 --> responsive

03. components
    3.01 --> buttons
    3.02 --> progress

04. layout
    4.01 --> header
    4.02 --> banner
    4.03 --> section
    4.04 --> footer

------------------------------------------------------------------*/

// Google fonts ***
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

// Abstracts styles ***
@import "_abstracts/_mixins";
@import "_abstracts/_variables";

// Typography styles***
@import "_base/_typography";

// Component styles ***
@import "_components/_buttons";
@import "_components/_progress";
@import "_components/_title";

// Layout styles ***
@import "_layout/_header";
@import "_layout/_banner";
@import "_layout/_section";
@import "_layout/_footer";

// Animation styles***
@import "_base/_animation";

// Responsive styles ***
@import "_base/_responsive";

// Style Resetter
.border-primary {
    border-color: var(--primary-color) !important;
}