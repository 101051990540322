/*-----------------------------------------------------------------

-------------------------------------------------------------------
CSS TABLE OF CONTENTS
-------------------------------------------------------------------

01. abstracts
    1.01 --> mixins
    1.02 --> variable

02. base
    2.01 --> typography
    2.02 --> animation
    2.03 --> responsive

03. components
    3.01 --> buttons
    3.02 --> progress

04. layout
    4.01 --> header
    4.02 --> banner
    4.03 --> section
    4.04 --> footer

------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
:root {
  --inter: "Inter", sans-serif;
  --poppins: "Poppins", sans-serif;
  --montserrat: var(--inter);
  --prosto-one: var(--montserrat);
  --primary-color: #fa4f09;
  --secondary-color: #000000;
  --sub-bg: #191919;
  --heading-color: #222222;
  --paragraph: #d2d2d2;
  --span: #ffffff;
  --border: #414141;
  --border-light: #a9a9a9;
  --white: #ffffff;
  --pure-white: #ffffff;
  --black: #000000;
  --footer-color: #727272;
  --input-placeholder: #d2d2d2;
  --border-1px: 1px solid #414141;
  --border-2px: 2px solid #414141;
  --transition: all 0.3s ease-in-out;
}

:root[data-theme=light] {
  --primary-color: #fa4f09;
  --secondary-color: #fff;
  --sub-bg: #f5f5f5;
  --heading-color: #000;
  --paragraph: #414141;
  --paragraph-light: #d2d2d2;
  --span: #000;
  --border: #dee2e6;
  --border-light: #221515;
  --white: #000;
  --black: #fff;
  --footer-color: #727272;
  --border-1px: 1px solid #d9d9d9;
  --border-2px: 2px solid #d9d9d9;
  --transition: all 0.3s ease-in-out;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--inter);
  color: var(--white);
  line-height: 28px;
  font-weight: 400;
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--poppins);
  font-weight: 600;
  letter-spacing: -0.5px;
}

span {
  display: inline-block;
  color: inherit;
}

del {
  color: #727272;
}

a {
  text-decoration: none;
  display: inline-block;
  color: var(--heading-color);
  transition: var(--transition);
  cursor: pointer !important;
}

button,
.btn {
  cursor: pointer;
}

a:hover {
  color: var(--heading-color);
}

ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

li {
  list-style: none;
}

button {
  border: none;
  background-color: transparent;
}

input {
  border: none;
  outline: none;
}

::placeholder {
  color: var(--span);
}

::selection {
  color: var(--white);
  background-color: var(--primary-color);
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

.bor {
  border: 1px solid var(--border);
}

.bor-top {
  border-top: 1px solid var(--border);
}

.bor-left {
  border-left: 1px solid var(--border);
}

.bor-bottom {
  border-bottom: 1px solid var(--border);
}

.bor-right {
  border-right: 1px solid var(--border);
}

.border-none {
  border: none !important;
}

.image img {
  width: 100%;
}

.primary-color {
  color: var(--primary-color);
}

.footer-color {
  color: var(--footer-color);
}

.primary-hover {
  transition: var(--transition);
}
.primary-hover:hover {
  color: var(--primary-color);
}

.primary-bg {
  background-color: var(--primary-color);
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.heading-color {
  color: var(--heading-color);
}

.sub-bg {
  background-color: var(--sub-bg);
}

.sm-font {
  font-size: 14px;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay, .gallery__image {
  position: relative;
}
.overlay::before, .gallery__image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.radius-10 {
  border-radius: 10px;
}

.fw-600 {
  font-weight: 600;
}

.lightPara-color {
  color: #d2d2d2;
}

.star i {
  color: #ed8a19;
  font-size: 14px;
}

.round-100 {
  border-radius: 100%;
}

.text-sm {
  font-size: 14px !important;
}

.text-narrow-md {
  letter-spacing: -0.3px !important;
}

.text-narrow-normal {
  letter-spacing: normal !important;
}

.cursror-pointer {
  cursor: pointer !important;
}

.css-1sl1ukt-MuiChip-root,
.css-1cprn2g-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-1h4bzp1-MuiButtonBase-root-MuiMenuItem-root,
.css-8voplp-MuiFormLabel-root-MuiInputLabel-root,
.css-j882ge-MuiInputBase-root-MuiOutlinedInput-root,
.css-m0kdz1-MuiButtonBase-root-MuiButton-root,
.css-10yf2i8-MuiInputBase-root-MuiInput-root,
.css-1edfpdg-MuiTypography-root,
.css-1o0kqxp-MuiInputBase-root-MuiOutlinedInput-root,
.css-1q964xs-MuiFormLabel-root-MuiInputLabel-root,
.css-rxaxkm-MuiPaper-root-MuiAlert-root,
.css-1hv8oq8-MuiStepLabel-label,
.css-10ukr6t-MuiTableCell-root,
.css-1ojhcqg-MuiTableCell-root,
table,
tr,
td,
th {
  font-family: var(--inter) !important;
}

button,
.css-1eh2dyd-MuiButtonBase-root-MuiButton-root,
.css-1g8ipf2-MuiButtonBase-root-MuiButton-root {
  font-family: var(--poppins) !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.css-8iab9z-MuiButtonBase-root-MuiButton-root {
  color: var(--primary-color) !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  color: var(--pure-white) !important;
}

.btn-outline-primary {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: transparent !important;
}
.btn-outline-primary:hover {
  background-color: var(--primary-color) !important;
  color: var(--pure-white) !important;
}

.btn-dark {
  color: var(--primary-color) !important;
  background-color: var(--white) !important;
}
.btn-dark:hover {
  background-color: var(--primary-color) !important;
  color: var(--pure-white) !important;
}

.btn-one, .btn-one-light, .btn-two {
  display: inline-block;
  vertical-align: middle;
  background-color: var(--primary-color);
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  padding: 12px 30px;
  transition: var(--transition);
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 100px;
}
.btn-one:hover, .btn-one-light:hover, .btn-two:hover {
  color: var(--primary-color);
  background-color: var(--white);
}
.btn-one:hover::before, .btn-one-light:hover::before, .btn-two:hover::before {
  top: 100%;
}
.btn-one:hover::after, .btn-one-light:hover::after, .btn-two:hover::after {
  bottom: -50%;
}
.btn-one::before, .btn-one-light::before, .btn-two::before {
  content: "";
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  height: 150%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-color: var(--black);
  transition: 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.btn-one::after, .btn-one-light::after, .btn-two::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 150%;
  height: 150%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: var(--white);
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.btn-one span, .btn-one-light span, .btn-two span {
  position: relative;
  color: inherit;
  z-index: 2;
}

.btn-one-light, .btn-two {
  background-color: var(--white);
  color: var(--primary-color);
}
.btn-one-light:hover, .btn-two:hover {
  color: var(--white);
}
.btn-one-light::after, .btn-two::after {
  background-color: var(--primary-color);
}

.dot .swiper-pagination-bullet, .dot-light .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.6s;
  background-color: transparent;
  opacity: 1;
  position: relative;
  border: 1px solid transparent;
}
.dot .swiper-pagination-bullet::before, .dot-light .swiper-pagination-bullet::before {
  position: absolute;
  content: "";
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #858585;
  transition: 0.6s;
}
.dot .swiper-pagination-bullet.swiper-pagination-bullet-active, .dot-light .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1px solid var(--primary-color);
}
.dot .swiper-pagination-bullet.swiper-pagination-bullet-active::before, .dot-light .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  background-color: var(--primary-color);
}

.dot-light .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid transparent;
}
.dot-light .swiper-pagination-bullet::before {
  background-color: var(--white);
}
.dot-light .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1px solid var(--white);
}
.dot-light .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  background-color: var(--white);
}

.pagi-btn {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border: var(--border-1px);
}
.pagi-btn:hover {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.pagi-btn.active {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  color: var(--footer-color);
  border: var(--border-1px);
}
.social-icon a:hover {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.arry-btn button {
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  border-radius: 50%;
  border: 1px solid var(--border);
  color: var(--border);
  transition: var(--transition);
}
.arry-btn button:hover {
  background-color: var(--primary-color);
  color: var(--white);
  border: 1px solid var(--primary-color);
}
.arry-btn .active {
  background-color: var(--primary-color);
  color: var(--white);
  border: 1px solid var(--primary-color);
}

.arry-prev {
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 50%;
  border: 1px solid var(--border);
  color: var(--border);
  transition: var(--transition);
}
.arry-prev:hover {
  background-color: var(--primary-color);
  color: var(--white);
  border: 1px solid var(--primary-color);
}

.arry-next {
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 50%;
  transition: var(--transition);
  background-color: var(--primary-color);
  color: var(--white);
  border: 1px solid var(--primary-color);
}

.nav-pills .nav-item .nav-link {
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  border-radius: 0px;
  line-height: 12px;
  font-weight: 600;
  color: var(--footer-color);
  transition: var(--transition);
}
.nav-pills .nav-item .nav-link:hover {
  color: var(--white);
}
.nav-pills .nav-item .nav-link.active {
  color: var(--white);
  position: relative;
}
.nav-pills .nav-item .nav-link.active::after {
  position: absolute;
  content: "";
  left: 10px;
  top: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.off-btn {
  border: var(--border-1px);
  padding: 10px 18px;
  border-radius: 100px;
  font-weight: 600;
}

.off-tag {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  background-color: var(--white);
  color: var(--primary-color);
  font-size: 19px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 15px;
}

.video__btn-wrp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-btn {
  position: relative;
  text-align: center;
  display: inline-block;
  z-index: 2;
}
.video-btn a {
  position: relative;
  color: var(--primary-color);
  font-size: 28px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 50%;
  display: block;
  transition: 0.4s;
}

.video-pulse::after,
.video-pulse::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid var(--white);
  opacity: 0.7;
  left: 0;
  top: 0;
  border-radius: 50%;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: video-animation;
  animation-name: video-animation;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.video-pulse::before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes video-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes video-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
.radio-btn {
  display: flex;
  align-items: center;
}
.radio-btn p {
  color: var(--black);
  margin-left: 10px;
  font-size: 14px;
}
.radio-btn span {
  width: 20px;
  height: 20px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.radio-btn span::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--primary-color);
  top: 6.5px;
  left: 6.5px;
}
.radio-btn .radio-btn-active {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}
.radio-btn .radio-btn-active::after {
  background-color: var(--white);
}

.pace {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99999999999999;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  border: 0px;
  height: 1px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.pace .pace-progress {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  max-width: 300px;
  position: fixed;
  z-index: 99999999999999;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: var(--primary-color);
}

.pace.pace-inactive {
  width: 100vw;
  opacity: 0;
}
.pace.pace-inactive .pace-progress {
  max-width: 100vw;
}

#preloader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999999;
}
#preloader:after {
  content: "";
  position: fixed;
  left: 0;
  height: 50%;
  width: 100%;
  background: var(--black);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  bottom: 0;
}
#preloader:before {
  content: "";
  position: fixed;
  left: 0;
  height: 50%;
  width: 100%;
  background: var(--black);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  top: 0;
}

#preloader.isdone {
  visibility: hidden;
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
#preloader.isdone:after {
  height: 0;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
#preloader.isdone:before {
  height: 0;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.loading {
  position: fixed;
  width: 100%;
  text-align: center;
  left: 50%;
  top: calc(50% - 40px);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-weight: 400;
  font-size: 24px;
  text-transform: lowercase;
  letter-spacing: 5px;
  z-index: 9999999999;
}
.loading span {
  -webkit-animation: loading 1.4s infinite alternate;
  animation: loading 1.4s infinite alternate;
}
.loading span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.loading span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.loading span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loading span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.loading span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loading span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.loading span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.loading.isdone {
  top: 50%;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

@-webkit-keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}

.cursor-inner {
  width: 6px;
  height: 6px;
  z-index: 10000001;
  background-color: var(--primary-color);
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cursor-inner.cursor-hover {
  margin-left: -35px;
  margin-top: -35px;
  width: 70px;
  height: 70px;
  background-color: var(--primary-color);
  opacity: 0.3;
}

.cursor-outer {
  margin-left: -12px;
  margin-top: -12px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary-color);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  -o-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}

.cursor-outer.cursor-hover {
  opacity: 0;
}

.scroll-up {
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px var(--border);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  position: fixed;
  right: 25px;
  bottom: 35px;
  height: 50px;
  width: 50px;
  transition: var(--transition);
}
.scroll-up::after {
  position: absolute;
  font-family: "Font Awesome 6 Pro";
  content: "\f106";
  text-align: center;
  line-height: 50px;
  font-weight: 700;
  font-size: 18px;
  color: var(--primary-color);
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: var(--transition);
}
.scroll-up svg path {
  fill: none;
}
.scroll-up svg.scroll-circle path {
  stroke: var(--primary-color);
  stroke-width: 4px;
  box-sizing: border-box;
  transition: var(--transition);
}

.scroll-up.active-scroll {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.section-header .section-title-icon {
  position: relative;
}
.section-header .section-title-icon span {
  position: absolute;
  left: -30px;
  top: 10px;
}

.short .nice-select {
  border-radius: 6px;
  border: var(--border-1px);
  background-color: var(--black);
}
.short .nice-select .list {
  background-color: var(--black);
  border: var(--border-1px);
}
.short .nice-select .list .option.selected {
  background-color: var(--black);
  font-weight: normal;
}
.short .nice-select .list li:hover {
  background-color: var(--black);
  color: var(--primary-color);
}

.title-icon {
  width: 21px;
  height: 14px;
  position: relative;
}
.title-icon::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  top: 0;
  left: 0;
  animation: moveLeft 3s linear infinite;
}
.title-icon::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border: 2px solid var(--primary-color);
  top: 0;
  right: 0;
  animation: moveRight 3s linear infinite;
}

.top__header {
  width: 100%;
  background-color: #B2B2B2;
}
.top__header .top__wrapper .main__logo img {
  width: 170px;
}
@media (max-width: 991px) {
  .top__header .top__wrapper .main__logo {
    margin-right: 0;
    width: 140px;
  }
}
.top__header .top__wrapper .search__wrp {
  position: relative;
}
.top__header .top__wrapper .search__wrp input {
  background-color: var(--pure-white);
  outline: none;
  padding: 16px 5% 16px 53px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  text-transform: capitalize;
  min-width: 100%;
}
.top__header .top__wrapper .search__wrp input::placeholder {
  color: #a6a6a6;
}
.top__header .top__wrapper .search__wrp button {
  position: absolute;
  left: 20px;
  top: 18px;
}
.top__header .top__wrapper .search__wrp button i {
  color: var(--primary-color);
  font-size: 18px;
}
@media (max-width: 1600px) {
  .top__header .top__wrapper .search__wrp input {
    width: 400px;
  }
}
@media (max-width: 1399px) {
  .top__header .top__wrapper .search__wrp input {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .top__header .top__wrapper .search__wrp {
    display: none;
  }
}
.top__header .top__wrapper .account__wrap {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 991px) {
  .top__header .top__wrapper .account__wrap {
    gap: 15px;
  }
}
.top__header .top__wrapper .account__wrap .acc__cont span {
  color: #191919;
  font-weight: 500;
}
.top__header .top__wrapper .account__wrap .c__one span {
  color: #191919;
  font-weight: 600;
}
.top__header .top__wrapper .account__wrap .account {
  width: 156px;
  background-color: var(--white);
  border-radius: 100px;
  gap: 8px;
}
@media (max-width: 991px) {
  .top__header .top__wrapper .account__wrap .account {
    background-color: transparent;
    width: unset;
  }
}
.top__header .top__wrapper .account__wrap .account .user__icon {
  width: 51px;
  height: 50px;
  border-radius: 100px 0 0 100px;
  background-color: var(--primary-color);
  text-align: center;
  line-height: 50px;
}
.top__header .top__wrapper .account__wrap .account .user__icon a i {
  color: var(--white);
  font-size: 15px;
}
@media (max-width: 991px) {
  .top__header .top__wrapper .account__wrap .account .user__icon {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}
.top__header .top__wrapper .account__wrap .account span {
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .top__header .top__wrapper .account__wrap .account .acc__cont span {
    display: none;
  }
}
.top__header .top__wrapper .account__wrap .cart {
  width: 174px;
  border-radius: 100px;
  background-color: var(--white);
  gap: 19px;
}
.top__header .top__wrapper .account__wrap .cart .one {
  background-color: #191919;
  padding: 15px;
  font-weight: 500;
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
}
@media (max-width: 767px) {
  .top__header .top__wrapper .account__wrap .cart {
    background-color: transparent;
    width: unset;
  }
}
.top__header .top__wrapper .account__wrap .cart .cart__icon {
  width: 51px;
  height: 48px;
  text-align: center;
  line-height: 50px;
}
.top__header .top__wrapper .account__wrap .cart .cart__icon i {
  color: var(--primary-color);
}
@media (max-width: 767px) {
  .top__header .top__wrapper .account__wrap .cart .cart__icon {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top__header .top__wrapper .account__wrap .cart .cart__icon i {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .top__header .top__wrapper .account__wrap .cart .c__one {
    display: none;
  }
  .top__header .top__wrapper .account__wrap .cart .one {
    display: none;
  }
}
.top__header .top__wrapper .account__wrap .flag__wrap {
  position: relative;
  width: 119px;
}
@media (max-width: 767px) {
  .top__header .top__wrapper .account__wrap .flag__wrap {
    width: 80px;
  }
}
.top__header .top__wrapper .account__wrap .flag__wrap .nice-select {
  padding: 1px 28px 0px 55px;
  background: var(--white);
  border-radius: 100px;
  border: none;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .top__header .top__wrapper .account__wrap .flag__wrap .nice-select {
    padding: 10px 7px 10px 15px;
  }
}
.top__header .top__wrapper .account__wrap .flag__wrap .nice-select span {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--black);
}
.top__header .top__wrapper .account__wrap .flag__wrap .nice-select .list {
  width: 110px;
}
.top__header .top__wrapper .account__wrap .flag__wrap .nice-select .list li {
  color: var(--black);
}
.top__header .top__wrapper .account__wrap .flag__wrap .flag {
  position: absolute;
  top: 4px;
  left: 7px;
  z-index: 1;
}
@media (max-width: 767px) {
  .top__header .top__wrapper .account__wrap .flag__wrap .flag {
    display: none;
  }
}
.top__header .top__wrapper .account__wrap .flag__wrap .flag img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1600px) {
  .top__header .top__wrapper .account__wrap {
    gap: 14px;
  }
}
@media (max-width: 1199px) {
  .top__header .top__wrapper {
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .top__header {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

.top__header.top-header-one .search__wrp input {
  background-color: var(--pure-white);
  color: var(--white);
}
.top__header.top-header-one .account {
  background-color: var(--black) !important;
  border: var(--border-1px);
}
.top__header.top-header-one .top__wrapper .account__wrap .cart {
  background-color: var(--black);
  border: var(--border-1px);
}
.top__header.top-header-one .top__wrapper .account__wrap .cart .one {
  background-color: var(--primary-color);
}

.header-section {
  width: 100%;
  color: var(--pure-white);
  background-color: #191919;
}

.menu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #191919;
  border-bottom: unset;
}
.menu-fixed .header-wrapper .main-menu .logo {
  display: block;
}

.header-wrapper {
  transition: var(--transition);
}
.header-wrapper .search__wrp {
  position: relative;
  width: 100%;
}
.header-wrapper .search__wrp input {
  background-color: var(--pure-white);
  outline: none;
  padding-inline: 50px 40px;
  width: 100%;
  height: 45px;
  border-radius: 45px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  text-transform: capitalize;
}
.header-wrapper .search__wrp input::placeholder {
  color: #a6a6a6;
}
.header-wrapper .search__wrp button {
  position: absolute;
  left: 20px;
  top: 18px;
}
.header-wrapper .search__wrp button i {
  color: var(--primary-color);
  font-size: 18px;
}
.header-wrapper .main-menu {
  position: relative;
  display: flex;
  align-items: center;
}
.header-wrapper .main-menu .logo {
  display: none;
}
.header-wrapper .main-menu li {
  transition: all 0.6s;
  position: relative;
  text-transform: capitalize;
  border-radius: initial;
}
.header-wrapper .main-menu li:not(:last-child) {
  margin-right: 38px;
}
@media (max-width: 1199px) {
  .header-wrapper .main-menu li:not(:last-child) {
    margin-right: 20px;
  }
}
.header-wrapper .main-menu li a {
  color: var(--white);
  font-weight: 600;
  font-size: 14px;
  transition: var(--transition);
  padding: 15px 0;
  font-family: var(--inter);
}
.header-wrapper .main-menu li a:hover {
  color: var(--primary-color);
}
.header-wrapper .main-menu li .sub-menu {
  border-radius: 10px;
  margin: 0 10px 10px 10px;
  display: none;
  transform: translateY(15px);
}
@media (min-width: 992px) {
  .header-wrapper .main-menu li .sub-menu {
    position: absolute;
    top: 80%;
    left: -10px;
    z-index: 999;
    width: 260px;
    transition: var(--transition);
    background-color: var(--pure-white);
    display: block !important;
    border: 1px solid #f5f4ff;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  }
}
.header-wrapper .main-menu li .sub-menu li {
  margin-right: 0;
  transition: all 0.4s !important;
}
.header-wrapper .main-menu li .sub-menu li:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}
.header-wrapper .main-menu li .sub-menu li a {
  color: var(--white);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  display: block;
  padding: 8px 20px;
}
.header-wrapper .main-menu li .sub-menu li a i {
  font-size: 14px !important;
}
.header-wrapper .main-menu li .sub-menu li:hover a {
  margin-left: 10px;
  color: var(--primary-color);
}
.header-wrapper .main-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 991px) {
  .header-section {
    border-bottom: unset;
  }
  .main-menu {
    position: absolute;
    top: 100%;
    width: 100%;
    display: inline !important;
    left: 0;
    margin-top: 20px;
    z-index: 999;
    padding: 0px 0px;
    transition: all 2s !important;
    transform-origin: top;
    max-height: calc(100vh - 130px);
    overflow-y: auto;
    border-radius: 10px;
    background-color: var(--black-color);
  }
  .main-menu:not(.active) {
    display: none !important;
    transition: all 2s;
  }
  .main-menu li {
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    border-radius: 10px;
  }
  .main-menu li:not(:last-child) {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
  .main-menu li a {
    display: block;
    padding: 5px 10px;
    text-transform: capitalize;
    font-size: 18px !important;
  }
  .main-menu li .sub-menu {
    transform: translateY(0px) !important;
    margin: 0px 10px 15px 20px !important;
  }
  .main-menu li .sub-menu li {
    margin-bottom: 10px;
  }
  .main-menu li .sub-menu li a {
    padding: 10px 0px;
    color: var(--white) !important;
    font-size: 14px;
  }
  .main-menu li .sub-menu li a:hover {
    color: var(--primary-color);
  }
  .main-menu li .sub-menu li .sub-two {
    position: static !important;
    width: 100% !important;
    padding: 15px 10px 20px 30px;
  }
  .main-menu li .sub-menu li .sub-two li a {
    padding: 10px 15px !important;
    display: block;
  }
  .main-menu .btn--items {
    box-shadow: none;
    border: unset;
  }
  .main-menu .btn--items .cmn--btn {
    width: 140px;
    text-align: center;
    display: block !important;
    background: rgb(67, 58, 193);
    background: linear-gradient(90deg, rgb(67, 58, 193) 0%, rgb(11, 4, 131) 30%, rgb(0, 215, 255) 100%);
  }
  .main-menu .active a {
    color: var(--white);
  }
  .header-bar {
    position: relative;
    width: 40px;
    height: 20px;
  }
  .header-bar span {
    position: absolute;
    width: 100%;
    height: 2px;
    display: inline-block;
    transition: all 0.3s;
    left: 0;
    background: var(--white);
  }
  .header-bar span:first-child {
    top: 0;
    background: var(--white);
  }
  .header-bar span:nth-child(2) {
    top: 44%;
    width: 30px;
    background: var(--white);
  }
  .header-bar span:last-child {
    bottom: 0;
    width: 25px;
    background: var(--white);
  }
  .header-bar.active span:first-child {
    transform: rotate(45deg) translate(3px, 9px);
  }
  .header-bar.active span:nth-child(2) {
    opacity: 0;
  }
  .header-bar.active span:last-child {
    transform: rotate(-45deg) translate(3px, -9px);
    width: 40px;
  }
  .header-bar:hover {
    cursor: pointer;
  }
}
.logo {
  max-width: 158px;
}
.logo img {
  width: 100%;
}

.side-bar-btn i {
  font-size: 20px;
}

.side_bar {
  position: fixed;
  top: 0;
  right: 0px;
  width: 400px;
  height: 100%;
  background-color: var(--white);
  padding: 40px;
  padding-top: 65px;
  z-index: 9999;
  transition: var(--transition);
}
.side_bar p {
  color: var(--white);
}
.side_bar .info li {
  font-size: 20px;
}
.side_bar .info li i {
  margin-right: 10px;
}
.side_bar .info li a {
  color: var(--white);
}
.side_bar .side_bar_overlay {
  position: fixed;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: -1;
}
.side_bar button {
  position: absolute;
  right: 40px;
  top: 40px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  background-color: var(--primary-color);
  border-radius: 50%;
  transition: var(--transition);
}
.side_bar button:hover {
  transform: rotate(90deg);
}
.side_bar .social-icon a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border: var(--border-1px);
  color: var(--primary-color);
  margin-right: 10px;
}
.side_bar .social-icon a:hover {
  background-color: var(--primary-color);
  color: var(--heading-color);
  border: 1px solid var(--primary-color);
}

.side_bar_hidden {
  visibility: hidden;
  opacity: 0;
  right: -30px;
}

.mobile-navigation .css-j400xw-MuiPaper-root-MuiDrawer-paper {
  width: 85%;
}
.mobile-navigation .offcanvas-header {
  margin-bottom: 10px;
  padding: 15px;
  background-color: #1a2b3c;
}
.mobile-navigation .offcanvas-header .offcanvas-title {
  font-family: var(--poppins);
  font-weight: 600;
  color: var(--pure-white);
}
.mobile-navigation .accordion .accordion-item {
  border-radius: 0;
  border-top: 0 !important;
}
.mobile-navigation .accordion .accordion-button {
  padding: 10px 15px 10px 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0 !important;
  font-family: var(--poppins);
}
.mobile-navigation .accordion .accordion-button:not(.collapsed) {
  background-color: hsl(17, 96%, 95%) !important;
  box-shadow: none !important;
  color: var(--primary-color) !important;
}
.mobile-navigation .accordion .accordion-button:focus {
  box-shadow: none !important;
  border-color: hsl(17, 96%, 95%) !important;
}
.mobile-navigation .accordion .accordion-body {
  padding: 0;
}
.mobile-navigation .accordion .accordion-body ul li a {
  display: block;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  padding: 10px 15px 10px 25px;
}
.mobile-navigation .accordion .accordion-collapse {
  border-radius: 0;
}

.cart-drawer .css-eydqou-MuiPaper-root-MuiDrawer-paper {
  width: 400px;
}
.cart-drawer .drawer-header {
  margin-bottom: 10px;
  padding: 20px;
  background-color: #1a2b3c;
}
.cart-drawer .drawer-header .cart-drawer-title {
  font-family: var(--poppins);
  font-weight: 600;
  color: var(--pure-white);
  margin-bottom: 0;
}
.cart-drawer .drawer-body {
  font-family: var(--poppins);
  padding: 20px;
  height: 100%;
}
.cart-drawer .drawer-body .cart-product {
  display: flex;
  align-items: center;
  gap: 15px;
}
.cart-drawer .drawer-body .cart-product:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.cart-drawer .drawer-body .cart-product img {
  width: 70px;
  height: 70px;
}
.cart-drawer .drawer-body .cart-product .product-meta span {
  font-size: 15px;
}
.cart-drawer .drawer-footer {
  padding: 20px;
}

.banner-area {
  padding-top: 350px;
}
.banner__content {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}
.banner__content h5 {
  margin-bottom: 20px;
}
.banner__item {
  position: relative;
}

.banner-two {
  overflow: hidden;
  position: relative;
}
.banner-two .hero-banner {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.banner-two-light .banner-two__content h4 {
  color: var(--black);
}
.banner-two-light .banner-two__content h1 {
  color: var(--black);
}
.banner-two-light .banner-two__content p {
  color: var(--paragraph-light);
}
.banner-two-light .banner-two__content .banner-two__info span {
  color: var(--paragraph-light);
}
.banner-two-light .banner-two__content .banner-two__info h3 {
  color: var(--black);
}

.page-banner {
  text-align: center;
}
.page-banner .breadcrumb-list a,
.page-banner .breadcrumb-list span {
  margin-right: 6px;
  color: var(--pure-white);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.page-banner .breadcrumb-list a:hover,
.page-banner .breadcrumb-list span:hover {
  color: var(--primary-color);
}

.breadcrumb-list a,
.breadcrumb-list span {
  margin-right: 10px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.breadcrumb-list a:hover,
.breadcrumb-list span:hover {
  color: var(--primary-color);
}

.ad-banner-area {
  overflow: hidden;
}
.ad-banner__content {
  position: relative;
}
.ad-banner__content p {
  font-size: 20px;
  font-weight: 600;
}
.ad-banner__content.left {
  margin-left: 25%;
}
.ad-banner__item {
  position: relative;
  overflow: hidden;
}
.ad-banner__item .bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: var(--transition);
}
.ad-banner__item:hover .bg-image {
  transform: scale(1.1);
}

.product__image {
  position: relative;
  margin: 0 20px;
}
.product__image .back-image {
  position: absolute;
  top: 20px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}
.product__image .font-image {
  transition: var(--transition);
  opacity: 1;
  visibility: visible;
}
.product__image img {
  max-width: 100%;
  min-height: 300px;
}
.product__content {
  padding: 30px;
}
.product__content del,
.product__content span {
  font-size: 20px;
  font-weight: 600;
}
.product__item {
  border: 1px solid #e9ecef;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: var(--transition);
}
.product__item .wishlist {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  position: absolute;
  top: 30px;
  right: 30px;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  transform: translateX(-10px);
  z-index: 1;
  background-color: var(--pure-white);
}
.product__item .wishlist:hover {
  color: var(--pure-white);
  background-color: var(--primary-color);
}
.product__item:hover {
  transform: translateY(-10px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}
.product__item:hover .font-image {
  opacity: 0;
  visibility: hidden;
}
.product__item:hover .back-image {
  opacity: 1;
  visibility: visible;
}
.product__item:hover .wishlist {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}
.product__left-item {
  padding: 30px;
  border-radius: 10px;
}
.product__left-item .star {
  margin-top: 16px;
}
.product__left-item .left-title {
  font-size: 24px;
}
.product__coundown span {
  font-size: 14px;
  margin-top: 5px;
}
.product__coundown .coundown-item {
  text-align: center;
}
.product__coundown .coundown-item span {
  width: 50px;
  height: 50px;
  line-height: 52px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  font-size: 20px;
  background-color: var(--primary-color);
}
.product__coundown .coundown-item h6 {
  color: #a6a6a6;
  margin-top: 10px;
}
.product__cart {
  text-align: center;
  padding: 20px;
  position: relative;
  font-weight: 600;
  border-top: 1px solid #e9ecef;
}
.product__cart span,
.product__cart i {
  position: relative;
  z-index: 1;
  transition: var(--transition);
}
.product__cart::after, .product__cart::before {
  position: absolute;
  content: "";
  background-color: var(--primary-color);
  width: 0%;
  height: 100%;
  right: 0;
  top: 0;
  transition: var(--transition);
}
.product__cart::before {
  left: 0;
}
.product__cart:hover::after, .product__cart:hover::before {
  width: 50%;
}
.product__cart:hover span,
.product__cart:hover i {
  color: var(--pure-white);
}
.product__slider-item {
  border-radius: 10px;
  position: relative;
}
.product__slider-item .wishlist {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border: var(--border-2px);
  position: absolute;
  top: 30px;
  right: 30px;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  transform: translateX(-10px);
  z-index: 1;
}
.product__slider-item .wishlist:hover {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}
.product__slider-item:hover .wishlist {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}

.gallery-area .product__image img {
  min-height: 450px;
  max-height: 450px;
  border-radius: 10px;
}

.checkout__item-left {
  padding: 30px;
  border-radius: 10px;
}
.checkout__item-left input {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid var(--border);
  transition: var(--transition);
  border-radius: 10px;
  color: var(--white);
  background-color: transparent;
}
.checkout__item-left input:focus {
  border: 1px solid var(--primary-color);
}
.checkout__item-left label {
  font-weight: 500;
  color: var(--heading-color);
}
.checkout__item-left h5 {
  font-weight: 500;
}
.checkout__item-left .nice-select {
  border-radius: 10px;
  width: 100%;
  border: 1px solid var(--border);
  font-size: 16px;
  line-height: 1px;
  padding: 30px 20px;
  background-color: transparent;
}
.checkout__item-left .nice-select span {
  color: var(--paragraph);
  font-weight: 600;
}
.checkout__item-left .nice-select .list {
  width: 100%;
  color: var(--black);
}
.checkout__item-left textarea {
  border-radius: 10px;
  width: 100%;
  padding: 15px 20px;
  border: 1px solid var(--border);
  height: 200px;
  resize: none;
  transition: var(--transition);
  color: var(--white);
  background-color: transparent;
}
.checkout__item-left textarea::placeholder {
  color: var(--paragraph);
}
.checkout__item-left textarea:focus {
  border: 1px solid var(--primary-color);
}
.checkout__item-right {
  border-radius: 10px;
  padding: 30px;
}
.checkout__item-right ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout__item-right ul li a:hover {
  color: var(--primary-color);
}

.checkout-area .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: var(--border);
}
.checkout-area .css-1o0kqxp-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color);
}
.checkout-area .css-ezppyz-MuiButtonBase-root-MuiRadio-root.Mui-checked,
.checkout-area .css-1y5vrnu-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.checkout-area .css-1y5vrnu-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.checkout-area .css-18pjc51-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--primary-color) !important;
}
.checkout-area .shipping-address-accordian .css-rolk5j-MuiAccordion-heading {
  font-family: var(--poppins);
  font-weight: 600;
}
.checkout-area .shipping-address-accordian .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #f8f9fa;
}
.checkout-area .shipping-address-accordian .css-15v22id-MuiAccordionDetails-root {
  border-top: 1px solid var(--border);
  padding: 20px;
}
.checkout-area .checkout-title {
  font-size: 18px;
  letter-spacing: -0.05px;
  font-family: var(--poppins);
  font-weight: 600;
}
.checkout-area .order-summary {
  padding: 30px;
  border: 1px solid var(--border);
  border-radius: 8px;
}
.checkout-area .order-summary .product-info .product-title {
  font-family: var(--poppins);
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
}
.checkout-area .order-summary .price {
  font-size: 16px;
}
.checkout-area li.list-group-item {
  font-size: 15px;
  padding: 6px 15px;
}
.checkout-area .w-ch {
  width: 8ch;
  display: inline-block;
}
.checkout-area table tr:last-child td {
  border-bottom: none;
}

.get-now-area {
  overflow: hidden;
}
.get-now__image {
  max-width: 648px;
  height: 648px;
  line-height: 648px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: var(--sub-bg);
  position: relative;
}
.get-now__image .get-bg-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  max-width: 520px;
  width: 100%;
}
.get-now__image .get-bg-image img {
  width: 100%;
}
.get-now__image .image {
  max-width: 410px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.get-now__content ul {
  padding-left: 17px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}
.get-now__content ul li {
  font-weight: 600;
  list-style: disc;
}
.get-now__content .get-info {
  font-size: 24px;
}
.get-now__content .get-info span {
  font-weight: 700;
}
.get-now__content .get-info del {
  font-weight: 500;
  margin-right: 20px;
}
.get-now__content .time-up .info span {
  font-size: 18px;
  margin-top: 10px;
}
.get-now__content .time-up .get-time {
  width: 70px;
  height: 70px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  padding-top: 10px;
  border: var(--border-1px);
}
.get-now__content .time-up .get-time h3 {
  line-height: 30px;
  margin-bottom: 0;
}
.get-now__content .time-up .get-time span {
  color: var(--footer-color);
  font-weight: 600;
}
.get-now-arry {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 2;
}
.get-now-arry i {
  color: #d9d9d9;
  font-size: 26px;
}
.get-now__arry-right {
  left: inherit;
  right: 20px;
}

.cart-page {
  overflow: hidden;
}
.cart-page .column-labels {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.product-details {
  width: 40%;
}
.product-details img {
  width: 80px;
}

.product-price {
  width: 10%;
}

.product-quantity {
  width: 14%;
}
.product-quantity input {
  border: 1px solid var(--border);
  width: 100px;
  text-align: center;
  border-radius: 10px;
}

.product-removal {
  width: 6%;
  text-align: right;
}
.product-removal i {
  transition: var(--transition);
}
.product-removal i:hover {
  color: var(--primary-color) !important;
}

.product-line-price {
  width: 10%;
}

.total-section {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 767px) {
  .shopping-cart:is(.mobile-view) .w-20 {
    min-width: 20ch;
  }
  .shopping-cart:is(.mobile-view) .w-10 {
    min-width: 10ch;
  }
  .shopping-cart:is(.mobile-view) .column-labels {
    display: none;
  }
  .shopping-cart:is(.mobile-view) .product {
    position: relative;
    border-top: none !important;
    gap: 14px;
  }
  .shopping-cart:is(.mobile-view) .product .product-quantity {
    justify-content: center;
    width: auto;
  }
  .shopping-cart:is(.mobile-view) .product .quantity-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .shopping-cart:is(.mobile-view) .product div {
    width: 100%;
    text-align: center;
  }
  .shopping-cart:is(.mobile-view) .product .product-removal .remove-product {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
.shop-single {
  overflow: hidden;
}
.shop-single .swiper-slide img {
  border-radius: 10px;
}
.shop-single .shop-singe-tab .nav-pills .nav-link {
  padding-top: 15px;
  padding-bottom: 15px;
}
.shop-single .shop-singe-tab .nav-pills .nav-link::after {
  display: none;
}
.shop-single .shop-singe-tab .nav-pills .nav-link.active {
  background: transparent;
}
.shop-single .shop-singe-tab .nav-pills .nav-link.active h4 {
  color: var(--primary-color);
}
.shop-single .shop-slider-thumb img {
  cursor: pointer;
}
.shop-single .shop-slider-thumb .swiper-slide {
  border: 1px solid transparent;
  opacity: 0.5;
  transition: var(--transition);
}
.shop-single .shop-slider-thumb .swiper-slide.swiper-slide-thumb-active {
  border: 1px solid var(--primary-color);
  opacity: 1;
  border-radius: 10px;
}
.shop-single .cart-quantity {
  padding-right: 15px;
}
.shop-single .cart-quantity input {
  width: 50px;
  height: 40px;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--border);
}
.shop-single .cart-quantity .qty {
  color: var(--heading-color);
  font-weight: 500;
  border-radius: 10px;
}
.shop-single .cart-quantity .minus {
  color: var(--heading-color);
  border-radius: 10px;
}
.shop-single .cart-quantity .plus {
  color: var(--heading-color);
  border-radius: 10px;
}
.shop-single .cart-wrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.shop-single .cart-wrp .discount input {
  border: 1px solid var(--border);
  padding: 8px 15px;
  background-color: transparent;
  border-radius: 10px;
  color: var(--white);
}
@media screen and (max-width: 575px) {
  .shop-single .cart-wrp .discount {
    margin-top: 10px;
  }
}
.shop-single .abmin img {
  border-radius: 50%;
}
.shop-single .abmin .content {
  border-radius: 10px;
}
.shop-single .abmin .content::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  background-color: var(--black);
  border-left: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  transform: rotate(45deg);
  top: 40%;
  left: -16px;
}
.shop-single .comment-form input {
  background-color: transparent;
  border-radius: 10px;
  color: var(--white);
}
.shop-single .comment-form textarea {
  background-color: transparent;
  border-radius: 10px;
  color: var(--white);
  height: 250px;
}
.shop-single .fa-star,
.shop-single .fa-star-half-stroke {
  color: #ff9200;
}
.shop-single__rate-now {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.shop-single__rate-now p {
  font-weight: 500;
  color: var(--heading-color);
}
.shop-single__rate-now .star span i {
  color: var(--span);
  cursor: pointer;
}

.category__image {
  position: relative;
  width: 210px;
  height: 210px;
  line-height: 210px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  margin: 0 auto;
  border: 1px solid #232323;
}
.category__image .category-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--sub-bg);
  width: 170px;
  height: 170px;
  line-height: 170px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
}
.category__item h4 {
  font-family: var(--poppins);
  font-weight: 600;
}
.category__item:hover h4 a {
  color: var(--primary-color);
}
.category__item:hover .category__image .category-icon {
  background-color: rgba(0, 0, 0, 0.8);
}
.category__item:hover .category__image .category-icon img {
  filter: invert(49%) sepia(60%) saturate(5401%) hue-rotate(354deg) brightness(96%) contrast(105%);
}

.category-two__item:hover .category__image .category-icon {
  background-color: rgba(250, 79, 9, 0.7);
}
.category-two__item:hover .category__image .category-icon img {
  filter: none;
}

.discount-area {
  overflow: hidden;
}

.testimonial {
  overflow: hidden;
}
.testimonial__head-wrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.testimonial__item p {
  font-size: 20px;
  line-height: 35px;
  color: var(--footer-color);
}
.testimonial__item .testi-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testimonial__item .testi-header .testi-content span {
  color: var(--primary-color);
  padding-top: 10px;
  font-weight: 600;
}
.testimonial__item .testi-header i {
  font-size: 60px;
  color: var(--primary-color);
}
.testimonial__image {
  position: relative;
}
.testimonial__image img {
  width: 100%;
}
.testimonial__image::after {
  position: absolute;
  content: url(../../assets/images/testimonial/testimonial-line.png);
  left: -80px;
  top: 5px;
}

.blog {
  position: relative;
}
.blog__head-wrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.blog__item-left {
  border-radius: 10px;
  border: var(--border-1px);
  background-color: var(--sub-bg);
  padding: 30px;
}
.blog__item-left-content h3 {
  margin-top: 30px;
}
.blog__item-left-content h3 a:hover {
  color: var(--primary-color);
}
.blog__item-left-content p {
  margin-top: 20px;
  margin-bottom: 40px;
}
.blog__item-left-content-info {
  color: var(--footer-color);
}
.blog__item-left-dot-wrp {
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 1;
}
.blog__item-right {
  border: var(--border-1px);
  border-radius: 10px;
  padding: 30px;
  background-color: var(--sub-bg);
}
.blog__item-right h3 a {
  margin: 20px 0;
}
.blog__item-right h3 a:hover {
  color: var(--primary-color);
}
.blog__item-right span {
  color: var(--footer-color);
}
.blog__tag {
  color: var(--primary-color) !important;
  padding: 2px 10px;
  background-color: var(--white);
  border-radius: 100px;
}

.blog-slingle .image img {
  border-radius: 10px;
}
.blog-slingle .item {
  padding: 0 30px;
}
.blog-slingle .info span a {
  color: var(--span);
}
.blog-slingle .info span a:hover {
  color: var(--primary-color);
}
.blog-slingle .info_dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--border);
  margin: 0 10px;
}
.blog-slingle .list_here {
  padding-left: 30px;
}
.blog-slingle .list_here li {
  list-style-type: disc;
}
.blog-slingle .blog_testimonial {
  padding: 40px 65px;
  border-radius: 10px;
}
.blog-slingle .blog_testimonial p {
  font-size: 24px;
  line-height: 40px;
  color: var(--heading-color);
}
.blog-slingle .tag-share {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-slingle .tag-share .tag a {
  padding: 2px 10px;
  background-color: var(--sub-bg);
  border-radius: 30px;
  color: var(--span);
  margin-left: 2px;
  font-size: 14px;
}
.blog-slingle .tag-share .share a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--border);
  color: var(--span);
  margin-left: 2px;
}
.blog-slingle .tag-share .share a:hover {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.blog-slingle .arry a i {
  transition: var(--transition);
}
.blog-slingle .arry a:hover i {
  color: var(--primary-color);
}
.blog-slingle .recent-post {
  padding: 20px;
  display: flex;
  align-items: center;
}
.blog-slingle .recent-post .con {
  padding-left: 15px;
}
.blog-slingle .recent-post .con span {
  font-size: 14px;
  padding-bottom: 3px;
}
.blog-slingle .recent-post .con h5 a {
  text-transform: capitalize;
}
.blog-slingle .recent-post .con h5 a:hover {
  color: var(--primary-color);
}
.blog-slingle .right-item {
  border-radius: 10px;
  padding: 30px;
}
.blog-slingle .right-item h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}
.blog-slingle .right-item .search {
  position: relative;
}
.blog-slingle .right-item .search input {
  width: 100%;
  padding: 15px 20px;
  background-color: #dadada;
  border: none;
  border-radius: 10px;
}
.blog-slingle .right-item .search input::placeholder {
  color: #646464;
}
.blog-slingle .right-item .search button {
  color: #646464;
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 20px;
  background-color: #dadada;
}
.blog-slingle .right-item .right_list {
  padding-left: 30px;
}
.blog-slingle .right-item .right_list li {
  list-style-type: disc;
}
.blog-slingle .right-item .tags a {
  padding: 5px 10px;
}
.blog-slingle .right-item a {
  text-transform: capitalize;
}
.blog-slingle .right-item a:hover {
  color: var(--primary-color);
}

.blog-slingle.blog-area h3 a:hover {
  color: var(--primary-color);
}
.blog-slingle.blog-area .item {
  padding: 30px;
  border-radius: 10px;
}
.blog-slingle.blog-area .go_blog {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: var(--white);
  border-radius: 10px;
  background-color: var(--primary-color);
}
.blog-slingle.blog-area .go_blog i {
  transform: rotate(-45deg);
  transition: var(--transition);
}
.blog-slingle.blog-area .go_blog:hover i {
  transform: rotate(0deg);
}
.blog-slingle.blog-area .image-tag a {
  padding: 2px 10px;
  border-radius: 30px;
  color: var(--span);
  font-size: 14px;
}
.blog-slingle.blog-area .image-tag a:first-child {
  background-color: var(--primary-color);
}
.blog-slingle.blog-area .image-tag a:last-child {
  background-color: var(--sub-bg);
  margin-left: 3px;
}
.blog-slingle.blog-area .video_item {
  position: relative;
}
.blog-slingle.blog-area .video_item .video-btn-wrp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blog-pegi {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--border);
  margin-right: 5px;
}
.blog-pegi:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.blog_pegi_arrow {
  transition: var(--transition);
}
.blog_pegi_arrow:hover {
  color: var(--primary-color);
}

.blog-pegi.active {
  background-color: var(--primary-color);
  color: var(--white);
}

.brand__item {
  cursor: pointer;
}
.brand__item img {
  transition: var(--transition);
}
.brand__item:hover img {
  filter: brightness(0) invert(1);
}

.service-area {
  overflow: hidden;
}
.service__icon {
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border: var(--border-1px);
  position: relative;
}
.service__icon img {
  position: relative;
  z-index: 2;
}
.service__icon::after {
  position: absolute;
  content: "";
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
  transform: scale(0);
}
.service__content p {
  margin-top: 10px;
  font-size: 14px;
}
.service__image {
  position: relative;
}
.service__image .service-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  justify-content: center;
}
.service__item {
  display: flex;
  align-items: center;
  gap: 15px;
}
.service__item:hover .service__icon {
  border: 1px solid transparent;
}
.service__item:hover .service__icon::after {
  background-color: var(--primary-color);
  transform: scale(1);
}

.gallery__image {
  position: relative;
}
.gallery__image img {
  border-radius: 10px;
}
.gallery__image::before {
  background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1a1a1a 100%);
  border-radius: 10px;
}
.gallery__content {
  position: absolute;
  bottom: 40px;
  left: 40px;
}
.gallery__content h3 a:hover {
  color: var(--primary-color);
}
.gallery__item {
  position: relative;
}
.gallery__item .off-tag {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;
}

.view-area {
  position: relative;
}
.view__bg {
  position: absolute;
  left: 0;
  bottom: -130px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.view__left-content {
  padding: 40px;
  padding-top: 30px;
}
.view__left-content p {
  margin-top: 30px;
  margin-bottom: 50px;
}
.view__left-content .off-btn {
  float: right;
}
.view__left-item {
  border-radius: 10px;
  overflow: hidden;
}
.view__content {
  padding: 20px;
  padding-left: 40px;
}
.view__content p {
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 600;
}
.view__image {
  width: 245px;
}
.view__image img {
  width: 100%;
}
.view__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--sub-bg);
  border-radius: 10px;
  overflow: hidden;
}

.text-slider {
  overflow: hidden;
  font-size: 80px;
  line-height: 65px;
  color: var(--heading-color);
  text-transform: capitalize;
  font-family: var(--prosto-one);
}

.marquee-wrapper {
  position: relative;
  height: 105px;
  overflow: hidden;
  white-space: nowrap;
}
.marquee-inner {
  position: absolute;
  display: inline-flex;
  width: 200%;
}
.marquee-list {
  float: left;
  width: 50%;
}
.marquee-item {
  float: left;
  transition: animation 0.2s ease-out;
}
.marquee-item img {
  margin-top: -5px;
}

.marquee-inner.to-right {
  animation: marqueeRight 30s linear infinite;
}

@keyframes marqueeRight {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
.marquee-inner.to-left {
  animation: marqueeLeft 30s linear infinite;
}

@keyframes marqueeLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.form-area form input {
  width: 100%;
  padding: 15px 20px;
  background-color: var(--sub-bg);
  color: var(--white);
  border-radius: 10px;
}
.form-area form textarea {
  width: 100%;
  padding: 15px 20px;
  background-color: var(--sub-bg);
  border: 1px solid var(--border);
  margin-top: 30px;
  height: 200px;
  resize: none;
  border-radius: 10px;
}
.form-area form button {
  padding: 12px 26px;
  background-color: var(--primary-color);
  font-weight: 600;
  color: var(--white);
  border-radius: 10px;
}
.form-area form button i {
  padding-left: 6px;
  transform: rotate(-40deg);
  transition: var(--transition);
}
.form-area form button:hover i {
  transform: rotate(0deg);
}

.contact form input {
  margin-bottom: 30px;
  transition: var(--transition);
}
.contact form input:focus {
  border: 1px solid var(--primary-color);
}
.contact form textarea {
  transition: var(--transition);
  color: var(--white);
}
.contact form textarea:focus {
  border: 1px solid var(--primary-color);
}
.contact form .nice-select {
  width: 100%;
  border: 1px solid var(--border);
  font-size: 16px;
  line-height: 1px;
  padding: 30px 20px;
  background-color: var(--sub-bg);
  border-radius: 10px;
}
.contact form .nice-select span {
  color: var(--span);
}
.contact form .nice-select ul {
  width: 100%;
  border-radius: 10px;
}
.contact form .nice-select ul li {
  color: var(--black);
  font-weight: 600;
}
.contact .content {
  color: var(--white);
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../assets/images/contact/01.jpg);
  padding: 40px 30px;
}
.contact .content h2 {
  color: var(--white);
  padding-bottom: 40px;
}
.contact .content .arry {
  margin: 50px 0;
}
.contact .content ul li {
  margin-bottom: 20px;
}
.contact .content ul li a {
  color: var(--white);
}
.contact .content ul li a i {
  color: var(--white);
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 10px;
}

.google-map iframe {
  width: 100%;
  height: 650px;
  margin-bottom: -10px;
}

.login-area {
  overflow: hidden;
}
.login__image {
  position: relative;
}
.login__image img {
  width: 100%;
  border-radius: 10px;
}
.login__image .btn-wrp {
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%) rotate(-90deg);
}
.login__image .btn-wrp a {
  padding: 10px 20px;
  border: 1px solid var(--white);
  color: var(--white);
  transition: var(--transition);
  border-radius: 10px;
}
.login__image .btn-wrp a:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.login__image .btn-wrp .active {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  margin: 0 15px;
}
.login__item {
  padding: 65px;
  border-radius: 10px;
}
.login__form .radio-btn .radio-btn-active {
  background-color: var(--primary-color);
  border: 1px solid var(--white);
}
.login__form .radio-btn p {
  color: var(--black);
}
.login__form .radio-btn span {
  background-color: var(--white);
}
.login__form .or {
  color: var(--black);
  text-align: center;
  display: block;
  font-weight: 500;
}
.login__with a {
  width: 100%;
  padding: 17px 30px;
  font-weight: 600;
  border: var(--border-1px);
  border-radius: 10px;
}
.login__with a:hover {
  color: var(--primary-color);
}
.login__with a img {
  margin-right: 65px;
}

.error {
  text-align: center;
  overflow: hidden;
}
.error h1 {
  font-size: 300px;
  color: var(--white);
  font-weight: 500;
  line-height: 250px;
}

.black-area .top__wrapper .search__wrp input {
  color: var(--black);
}
.black-area .top__wrapper .account__wrap .flag__wrap .nice-select .list li {
  color: unset !important;
}
.black-area .header-wrapper .main-menu li a {
  color: var(--pure-black);
}
.black-area .header-wrapper .main-menu li .sub-menu li a:hover {
  color: var(--primary-color);
}
.black-area .header-wrapper .shipping__item .menu__right .content p {
  color: var(--black);
}
.black-area .header-wrapper .shipping__item .menu__right .content .items .form__select span {
  color: var(--black);
}
.black-area .header-wrapper .shipping__item .menu__right .content .items .form__select .list li {
  color: var(--white);
}
.black-area .btn-one, .black-area .btn-one-light, .black-area .btn-two {
  background-color: var(--primary-color);
  color: var(--black);
}
.black-area .btn-one::after, .black-area .btn-one-light::after, .black-area .btn-two::after {
  background-color: var(--black);
}
.black-area .btn-one:hover, .black-area .btn-one-light:hover, .black-area .btn-two:hover {
  color: var(--primary-color);
}
.black-area .btn-one-light, .black-area .btn-two {
  background-color: var(--black);
  color: var(--primary-color);
}
.black-area .btn-one-light::after, .black-area .btn-two::after {
  background-color: var(--primary-color);
}
.black-area .btn-one-light:hover, .black-area .btn-two:hover {
  color: var(--black);
}
.black-area .category__image {
  border: 1px solid var(--border);
}
.black-area .category__image .category-icon {
  background-color: var(--paragraph);
}
.black-area .brand__item:hover img {
  filter: brightness(1) invert(0);
}
.black-area .footer-title {
  color: var(--black);
}

.gallery-light .gallery__content h3 a {
  color: var(--black);
}
.gallery-light .gallery__content p {
  color: var(--black);
}
.gallery-light .off-tag {
  background-color: var(--black);
}

.ns-light .nice-select li {
  color: var(--white) !important;
}

.ns-head .nice-select::after {
  border-bottom: 2px solid var(--black) !important;
  border-right: 2px solid var(--black) !important;
}

.footer-area {
  overflow: hidden;
}
.footer__item .footer-title {
  margin-bottom: 25px;
}
.footer__item ul li:not(:last-child) {
  margin-bottom: 6px;
}
.footer__item ul li span {
  display: inline-block;
  width: 10px;
  height: 10px;
  line-height: 10px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border: var(--border-1px);
  margin-right: 6px;
  transition: var(--transition);
}
.footer__item ul li a {
  font-size: 14px;
  color: var(--footer-color);
  font-weight: 500;
}
.footer__item ul li a:hover {
  color: var(--primary-color);
}
.footer__item ul li a:hover span {
  border: 1px solid var(--primary-color);
}
.footer__item.newsletter .subscribe {
  position: relative;
}
.footer__item.newsletter .subscribe input {
  width: 100%;
  background-color: var(--sub-bg);
  padding: 16px 24px;
  border-radius: 100px;
  border: var(--border-1px);
  color: var(--white);
}
.footer__item.newsletter .subscribe input::placeholder {
  font-size: 14px;
  color: var(--footer-color);
}
.footer__item.newsletter .subscribe button {
  color: var(--white);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  background-color: var(--primary-color);
  font-size: 17px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.footer__copy-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.footer__copy-text p {
  font-size: 14px;
  font-weight: 600;
  color: var(--footer-color);
}
.footer__copy-text p a {
  color: var(--footer-color);
}
.footer__copy-text .payment {
  max-width: 275px;
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-7px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sway {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(10px);
  }
}
.sway__animation {
  animation: sway 5s linear infinite alternate;
}

@keyframes sway_Y {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
.sway_Y__animation {
  animation: sway_Y 5s linear infinite alternate;
}

@media screen and (max-width: 991px) {
  br {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .section-header h2 {
    font-size: 28px;
    line-height: 40px;
  }
  .pt-130 {
    padding-top: 65px;
  }
  .pb-130 {
    padding-bottom: 65px;
  }
}
@media screen and (max-width: 470px) {
  .nav-pills .nav-item .nav-link {
    padding: 0 5px !important;
    font-size: 12px;
  }
  .nav-pills .nav-item .nav-link.active::after {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .section-header h2 {
    font-size: 18px;
    line-height: 30px;
  }
}
@media screen and (max-width: 991px) {
  .banner-area .product__dot {
    text-align: center;
  }
  .banner-two__content h4 {
    font-weight: 600;
    font-size: 16px;
    color: var(--white);
    margin-bottom: 30px;
  }
  .banner-two__content h1 {
    color: var(--white);
    font-size: 60px;
    line-height: 74px;
  }
  .banner-two__content p {
    font-weight: 500;
  }
  .banner-two__content .banner-two__info span {
    color: var(--border-light);
    font-weight: 500;
    font-size: 14px;
  }
  .ad-banner__content.left {
    margin-left: 0%;
    padding-left: 65px;
  }
  .ad-banner__item {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .banner-area {
    padding-top: 250px;
  }
  .banner-two__arry-btn {
    display: none;
  }
  .banner-two__content h4 {
    font-weight: 600;
    font-size: 16px;
    color: var(--white);
    margin-bottom: 15px;
  }
  .banner-two__content h1 {
    color: var(--white);
    font-size: 30px;
    line-height: 40px;
  }
  .banner-two__content p {
    font-weight: 400;
    font-size: 14px;
    margin-top: 20px;
  }
  .banner-two__content .btn-wrp {
    margin-top: 30px;
  }
}
@media screen and (max-width: 575px) {
  .banner-area {
    padding-top: 200px;
  }
}
@media screen and (max-width: 424px) {
  .banner__content h5 {
    margin-bottom: 15px;
  }
  .banner__content h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .banner__content .btn-one, .banner__content .btn-one-light, .banner__content .btn-two {
    margin-top: 20px;
  }
  .banner-two .btn-wrp a {
    padding: 5px 15px;
  }
  .ad-banner__content {
    padding-left: 20px;
  }
  .ad-banner__content.left {
    padding-left: 20px;
  }
}
@media screen and (max-width: 424px) {
  .page-banner h2 {
    font-size: 30px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1399px) {
  .gallery__content {
    bottom: 30px;
    left: 30px;
  }
  .gallery__item .off-tag {
    top: 30px;
    left: 30px;
  }
  .testimonial__image::after {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .gallery__item {
    padding: 0 15px;
  }
  .product__content {
    text-align: center;
  }
  .get-now__image {
    background-color: var(--black);
  }
}
@media screen and (max-width: 575px) {
  .shop-page-wrp {
    flex-direction: column;
  }
  .shop-page-wrp .short {
    margin-top: 20px;
  }
  .testimonial .pb-65,
  .testimonial .pt-65,
  .testimonial .pl-65,
  .testimonial .pr-65,
  .testimonial .pr-55 {
    padding: 10px;
  }
  .testimonial__item p {
    font-size: 18px;
    line-height: 25px;
  }
  .login__item {
    padding: 20px;
  }
  .login__content h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .login__image .btn-wrp {
    right: -90px;
  }
}
@media screen and (max-width: 424px) {
  .blog-list .container-fluid {
    padding: 0 20px;
  }
  .get-now__image {
    height: 400px;
    line-height: 400px;
  }
  .discount__item .off-btn {
    padding: 10px 4px;
    font-size: 12px;
  }
  .discount__item .off-btn img {
    margin-right: 3px;
  }
}
@media screen and (max-width: 991px) {
  .footer__copy-text {
    flex-direction: column;
    padding: 30px 0;
  }
  .footer__copy-text p {
    margin: 15px 0;
  }
}
.border-primary {
  border-color: var(--primary-color) !important;
}

