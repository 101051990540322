:root {
	// Font family ***
	// --prosto-one: "Prosto One", sans-serif;
	--inter     : "Inter", sans-serif;
	--poppins   : "Poppins", sans-serif;
	--montserrat: var(--inter);
	--prosto-one: var(--montserrat);

	// Colors ***
	--primary-color    : #fa4f09;
	--secondary-color  : #000000;
	--sub-bg           : #191919;
	--heading-color    : #222222;
	--paragraph        : #d2d2d2;
	--span             : #ffffff;
	--border           : #414141;
	--border-light     : #a9a9a9;
	--white            : #ffffff;
	--pure-white       : #ffffff;
	--black            : #000000;
	--footer-color     : #727272;
	--input-placeholder: #d2d2d2;

	// border ***
	--border-1px: 1px solid #414141;
	--border-2px: 2px solid #414141;

	// Transitions ***
	--transition: all 0.3s ease-in-out;

	// Box shadow ***
	// --shadow: 0px 4px 55px 0px #4baf4726;
}

:root[data-theme="light"] {
	// Colors ***
	--primary-color     : #fa4f09;
	--secondary-color   : #fff;
	--sub-bg            : #f5f5f5;
	--heading-color     : #000;
	--paragraph         : #414141;
	--paragraph-light   : #d2d2d2;
	--span              : #000;
	// --border         : #d9d9d9;
	--border            : #dee2e6;
	--border-light      : #221515;
	--white             : #000;
	--black             : #fff;
	--footer-color      : #727272;

	// border ***
	--border-1px: 1px solid #d9d9d9;
	--border-2px: 2px solid #d9d9d9;

	// Transitions ***
	--transition: all 0.3s ease-in-out;
}