@media (max-width: 800px) {
    .marquee-item{
        font-size: 30px;
    }
}

@media (min-width : 801px) and (max-width: 1000px) {
    .marquee-item{
        font-size: 40px;
    }
}

@media (max-width : 460px) {
    .get-offer-button{
        margin-bottom: 10px;
    }
}

/* Media query for screens smaller than 768px (you can adjust the width if needed) */
/* @media (max-width: 768px) {
    .img-fluid {
        width: 67% !important;
    }
} */

.pointer{
    cursor: pointer !important;
}

.blogs-and-news{
    color: #fa4f09;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    margin-top: 15px;
    text-align: end;
    margin-right: 10px;
    cursor: pointer;
}

/* Ensure the arrows only apply to this specific swiper instance */
.custom-swiper .swiper-button-prev,
.custom-swiper .swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.custom-swiper .swiper-button-prev {
  left: 10px;
}

.custom-swiper .swiper-button-next {
  right: 10px;
}

.custom-swiper .swiper-button-prev:hover,
.custom-swiper .swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.trustpilot-section {
    display: flex;
    justify-content: center; /* Center horizontally */
  }


/* coming soon section */

.product-label {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 4px;
    z-index: 1;
  }



  /* Section Styling */
.brand-area {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.brand-heading {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  text-transform: capitalize;
  position: relative;
}

.brand-heading .title-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #fa4f09;
  border-radius: 50%;
  margin: 0 10px;
}

/* Brand Card */
.brand-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.brand-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.brand-image {
  width: 100%;
  max-width: 120px;
  height: auto;
  margin: 0 auto;
}

.brand-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-top: 10px;
  text-transform: capitalize;
}

/* Responsive Grid Adjustments */
@media (max-width: 768px) {
  .brand-image {
    max-width: 90px;
  }

  .brand-name {
    font-size: 14px;
  }

  .brand-card {
    padding: 10px;
  }
}

@media (max-width: 576px) {
  .brand-image {
    max-width: 70px;
  }

  .brand-name {
    font-size: 12px;
  }

  .brand-area {
    padding: 30px 0;
  }
}
/* Main Drawer Styles */
.mobile-nav-drawer .mobile-nav-header {
  background-color: #f9f9f9;
  padding: 20px 20px 10px; /* Increased spacing between the header and close button */
  border-bottom: 1px solid #ececec;
}

.mobile-nav-drawer .mobile-nav-title {
  font-size: 18px;
  font-weight: bold;
  color: #fa4f09; /* Soothing heading color */
}

.mobile-nav-drawer .mobile-nav-close-btn {
  background: none;
  border: none;
  color: #fa4f09;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 6px;
}

/* Accordion Button Styles */
.mobile-nav-drawer .mobile-nav-accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  font-size: 16px;
  background-color: #fff;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin-bottom: 5px; /* Space between items */
}

.mobile-nav-drawer .mobile-nav-accordion-button:hover {
  background-color: #f8f8f8;
}

.mobile-nav-drawer .mobile-nav-menu-icon {
  margin-right: 10px;
  color: #fa4f09;
}

.mobile-nav-drawer .mobile-nav-arrow-icon {
  margin-left: auto;
  color: #888;
  transition: transform 0.3s ease;
}

.mobile-nav-drawer .mobile-nav-accordion-button.collapsed .mobile-nav-arrow-icon {
  transform: rotate(90deg);
}

/* Submenu Styles */
.mobile-nav-drawer .mobile-nav-submenu-list {
  list-style: none;
  margin: 10px 0; /* Add spacing inside the submenu */
  padding: 0;
  border: 1px solid #ececec; /* Border around the submenu */
  border-radius: 5px; /* Rounded corners for the submenu */
  background-color: #f9f9f9; /* Light background */
}

.mobile-nav-drawer .mobile-nav-submenu-item {
  padding: 10px 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.mobile-nav-drawer .mobile-nav-submenu-item:hover {
  background-color: #ececec;
}

.mobile-nav-drawer .mobile-nav-submenu-icon {
  margin-right: 10px;
  color: #555;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .mobile-nav-drawer .mobile-nav-accordion-button {
    font-size: 14px;
  }

  .mobile-nav-drawer .mobile-nav-submenu-item {
    font-size: 12px;
  }
}
/* Filter Menu Styles */
.product-filter-menu-unique {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Accordion Item Styles */
.menu-accordion-unique {
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
}

.menu-accordion-item-unique {
  border-bottom: 1px solid #ddd;
  padding: 8px 0;
}

.menu-accordion-header-unique {
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.menu-icon-unique {
  color: #fa4f09;
  margin-right: 10px;
  font-size: 16px;
}

.menu-accordion-button-unique {
  background: none;
  border: none;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-accordion-button-unique:hover {
  color: #fa4f09;
}

.no-submenu-unique .menu-arrow-icon-unique {
  display: none; /* Hide arrow if no submenu */
}

.menu-arrow-icon-unique {
  margin-left: auto;
  font-size: 12px;
  color: #666;
  transition: transform 0.3s ease;
}

.menu-accordion-button-unique.collapsed .menu-arrow-icon-unique {
  transform: rotate(90deg);
}

/* Submenu Styles */
.menu-accordion-collapse-unique {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
}

.menu-accordion-body-unique {
  padding: 10px 15px;
}

.submenu-list-unique {
  list-style: none;
  padding: 0;
  margin: 0;
}

.submenu-item-unique {
  padding: 5px 0;
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.submenu-item-unique:hover {
  color: #fa4f09;
}

.submenu-icon-unique {
  color: #fa4f09;
  margin-right: 8px;
  font-size: 12px;
}

/* Product Display Section */
.product-display-section-unique {
  padding: 10px;
}

.product-display-section-unique .col-lg-4 {
  padding: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .product-filter-menu-unique {
    padding: 10px;
  }

  @media (max-width: 991px) {
    .product-filter-menu-unique {
      display: none !important;
    }
  }

  .menu-accordion-button-unique {
    font-size: 14px;
  }

  .submenu-item-unique {
    font-size: 12px;
  }
}
/* Blogs Section Styling */
.blogs-section-unique {
  padding: 50px 0;
  background-color: #f8f9fa;
  text-align: center;
}

.blogs-container-unique {
  padding: 0 20px; /* Add space inside the container */
}

/* Header Styling */
.blogs-header-unique {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 2px solid #ececec;
}

.blogs-title-wrapper-unique {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.blogs-title-icon-wrapper-unique {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #fa4f09;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blogs-icon-unique {
  color: #fff;
  font-size: 20px;
}

.blogs-title-unique {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Blog Item Styling */
.blog-item-unique {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-item-unique:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.blog-image-wrapper-unique {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.blog-image-unique {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-image-unique:hover {
  transform: scale(1.05);
}

/* Overlay Styling */
.blog-overlay-unique {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  text-align: left;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.blog-item-unique:hover .blog-overlay-unique {
  opacity: 1;
}

.blog-title-unique {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px 0;
  line-height: 1.2;
  color: #fff;
}

.blog-description-unique {
  font-size: 14px;
  color: #ddd;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .blog-image-wrapper-unique {
    height: 150px;
  }

  .blogs-title-unique {
    font-size: 20px;
  }
}
.custom-swiper .swiper-wrapper {
  padding: 0 !important; /* Override Swiper default padding */
}

/* Remove padding for Swiper containers */
.swiper.mySwiper {
  padding: 0 !important; /* Remove Swiper's default padding */
}

/* Optionally, handle any inherited paddings from other Swiper-related classes */
.swiper-horizontal {
  padding: 0 !important; /* Remove horizontal padding */
  margin-right: 0 !important; /* Remove horizontal padding */

}

/* Custom Swiper Styling */
.custom-swiper {
  padding: 0; /* Remove extra padding */
  margin: 0 auto; /* Center Swiper */
}

.custom-swiper-card {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa; /* Subtle background color */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.custom-swiper-card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Image Wrapper for Aspect Ratio */
.custom-image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* Aspect ratio */
  overflow: hidden;
  border-radius: 10px 10px 0 0; /* Rounded top corners */
}

/* Blog Image Styling */
.blog-image-unique {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

/* Blog Content Styling */
.blog-content-unique {
  padding: 15px;
  background-color: #fff;
  border-radius: 0 0 10px 10px; /* Rounded bottom corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Blog Title */
.blog-title-unique {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
}

/* Blog Description */
.blog-description-unique {
  font-size: 14px;
  color: #666;
  /* margin: 0; */
}

/* Swiper Adjustments for Small Screens */
@media (max-width: 768px) {
  .custom-swiper-card {
    margin: 5px;
  }

  .custom-image-wrapper {
    padding-bottom: 56.25%; /* Adjust ratio for smaller screens */
  }
}
/* Header Container */
.menu-photo-header {
  background-color: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0;
  position: relative; /* Not sticky */
  z-index: 1;
}

/* Menu Container */
.menu-photo-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Menu Wrapper */
.menu-photo-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Grid Styling */
.menu-photo-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between blocks */
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-photo-item:hover {
  transform: translateY(-5px); /* Slight hover effect */
}

/* Individual Block Styling */
.menu-photo-item {
  flex: 1 1 calc(16% - 20px); /* 6 items in a row with spacing adjustments */
  max-width: 16%; /* Prevents items from stretching */
  background: #f3f3f3;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease;
}


/* Image Wrapper */
.menu-photo-item-image-wrapper {
  background-color: lightgray;
  padding: 10px;
  height: 180px; /* Fixed height for consistency */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Label Styling */
.menu-photo-item-label {
  background-color: #333;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 5px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  line-height: 1.4;
  position: absolute;
  bottom: 0;
}

.menu-photo-item-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  background-color: lightgray;
}

/* Link Styling */
.menu-photo-item-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.menu-photo-item-link:hover {
  color: #fa4f09;
}

/* Hide Web Menu on Mobile */
@media (max-width: 992px) {
  .menu-photo-list {
    display: none;
  }
}



/* Info Card Section */
.info-card-section {
  margin: 0 auto;
  padding: 40px 20px; /* Page margins */
  max-width: 1200px;
}

/* Info Card Container */
.info-card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns for web */
  gap: 20px;
}

/* Info Card */
.info-card {
  /* background: white; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
}

.info-card:hover {
  transform: scale(1.03); /* Slight hover effect */
}

/* Info Card Icon */
.info-card-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
}

/* Background Colors */
.green-bg {
  background-color: white;
}

.blue-bg {
  background-color: white;
}

.yellow-bg {
  background-color: white;
}

.light-blue-bg {
  background-color: white;
}

/* Icon Colors */
.green-icon {
  color: #228b22;
  font-size: 24px;
}

.blue-icon {
  color: #00a2ff;
  font-size: 24px;
}

.yellow-icon {
  color: #ffbf00;
  font-size: 24px;
}

.light-blue-icon {
  color: #0066cc;
  font-size: 24px;
}

/* Info Card Content */
.info-card-content h4 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.info-card-content p {
  font-size: 14px;
  color: #777;
  margin: 5px 0 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .info-card-container {
    grid-template-columns: repeat(1, 1fr); /* One column for mobile */
    gap: 15px;
    padding: 0 15px; /* Add left/right margins for mobile */
  }

  .info-card {
    padding: 15px;
  }

  .info-card-icon {
    width: 50px;
    height: 50px;
  }

  .info-card-content h4 {
    font-size: 16px; /* Slightly smaller font size for mobile */
  }

  .info-card-content p {
    font-size: 12px; /* Adjusted font size */
  }
}


/* Hide on web (desktop and larger devices) */
.mobile-only-menu-photo-header {
  display: none;
}

@media (max-width: 768px) {
  /* Show only on mobile (max-width: 768px) */
  .mobile-only-menu-photo-header {
    display: block;
    background-color: #f9f9f9;
    padding: 10px 0;
  }

  .mobile-only-menu-photo-container {
    overflow-x: auto;
    white-space: nowrap; /* Horizontal scrolling */
    padding: 10px 15px;
  }

  .mobile-only-menu-photo-container::-webkit-scrollbar {
    height: 8px; /* Thin scrollbar */
  }

  .mobile-only-menu-photo-container::-webkit-scrollbar-thumb {
    background: #e0e0e0; /* Light grey scrollbar */
    border-radius: 10px; /* Rounded scrollbar */
  }

  .mobile-only-menu-photo-list {
    display: flex;
    gap: 20px; /* Space between items */
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .mobile-only-menu-photo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px; /* Consistent item size */
    text-align: center;
  }

  .mobile-only-menu-photo-item-image-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%; /* Circular shape */
    overflow: hidden;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e0e0e0; /* Circle border */
  }

  .mobile-only-menu-photo-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Fit image within circle */
  }

  .mobile-only-menu-photo-item-label {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #333;
    text-transform: capitalize;
    line-height: 1.2;
  }
}

.vape-info-section {
  text-align: center;
  padding: 40px 20px;
}

.vape-info-container {
  max-width: 800px;
  margin: 0 auto;
}

.vape-info-title {
  font-size: 28px;
  font-weight: 700;
  color: black;
  margin-bottom: 10px;
}

.vape-info-subtitle {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
}

.vape-info-text {
  font-size: 16px;
  line-height: 1.8;
  color: black;
  margin-bottom: 20px;
}


/* Horizontal Scroll Container */
.scrollable-nav-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  margin-bottom: 20px;
}

.scrollable-nav-container::-webkit-scrollbar {
  height: 6px;
}

.scrollable-nav-container::-webkit-scrollbar-thumb {
  background: #fa4f09;
  border-radius: 10px;
}

.scrollable-nav-container::-webkit-scrollbar-track {
  background: #ddd;
}

.nav {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.nav-item {
  flex-shrink: 0;
}

.vaping-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

