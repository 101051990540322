@media (max-width: 767px) {
  .top__header .top__wrapper .account__wrap .cart .cart__icon {
    background-color: white !important;
  }
  .cart-icon {
    color: black !important;
  }
}

@media (max-width : 991px){
  .search-div{
    display: none;
  }
  .img-fluid{
    width: 175px;
  }
}

@media (max-width : 400px){

  .img-fluid{
    width: 175px !important;
  }
}


@media (max-width : 400px){

  .img-fluidblogs{
    width: 400px !important;
  }
}

@media (max-width : 991px){
  .search-div{
    display: none;
  }
  .img-fluidblogs{
    width: 400px;
  }
}

@media (max-width: 767px) {
  .top__header .top__wrapper .account__wrap .cart {
      background-color: transparent;
      width: 40px !important;
  }
}






.cart-icon {
  color: white;
}

.order-message {
  font-size: 13px;
  color: white;
  text-align: center;
  border-radius: 3px;
  background-color: red;
}
.delivery-header-icon {
  color: black; /* Green color for delivery icon */
  margin-right: 10px;
}


/* Main menu styles */
.main-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #1a1a1a; /* Black background */
}

.menu-item {
  position: relative;
  margin-bottom: 5px;
}

/* Main menu item links */
.menu-item a {
  display: block;
  padding: 12px 20px;
  color: #fff; /* White text */
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect for main menu */
.menu-item a:hover {
  background-color: rgba(0, 0, 0, 0.6); /* Black with 60% opacity */
  color: #f5f5f5; /* Light text color */
}

/* Sub-menu styles */
.sub-menu {
  display: none; /* Hide sub-menu by default */
  position: absolute;
  top: 0;
  left: 100%;
  width: 500px; /* Adjusted width for sub-menu */
  background-color: #333; /* Dark background for sub-menu */
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: grid; /* Use grid layout for two columns */
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  gap: 15px; /* Space between columns */
}

.menu-item:hover .sub-menu {
  display: grid; /* Show sub-menu on hover */
}

/* Sub-menu item styles */
.sub-menu-item a {
  display: block;
  padding: 8px 15px;
  color: #ddd; /* Light gray text */
  font-size: 14px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect for sub-menu items */
.sub-menu-item a:hover {
  background-color: rgba(217, 205, 205, 0.8); /* Black with 60% opacity on hover */
  color: #f5f5f5; /* Light text color */
}

/* Arrow rotation for the dropdown */
.fa-angle-down {
  font-size: 12px;
  color: #bbb;
  transition: transform 0.3s ease;
}

/* Arrow rotation on hover */
.menu-item:hover .fa-angle-down {
  transform: rotate(180deg); /* Rotate the arrow when hovering */
}


/* Shared Styles */
/* Cart Count Popup - Web */
/* Cart Count Popup - Web */
.cart-count {
  position: absolute;
  top: -9px !important; /* Adjusted for web */
  right: 61px !important; /* Adjusted for web */
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

/* Cart Count Popup - Mobile */
@media screen and (max-width: 768px) {
  .cart-count {
    top: -16px !important; /* Adjusted for mobile */
    right: -5px !important; /* Adjusted for mobile */
    width: 16px; /* Smaller size for mobile */
    height: 16px; /* Smaller size for mobile */
    font-size: 10px;
  }
}



/* Header Wrapper */
.custom-header {
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Container */
.custom-container {
  max-width: 1140px;
  margin: 0 auto;
}

/* Header Content */
.custom-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Web Menu */
.custom-web-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.custom-menu-item {
  position: relative;
}

.custom-menu-item a {
  font-size: 16px;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
}

.custom-menu-item a:hover {
  color: #fa4f09;
}

.custom-sub-menu {
  width: 211px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
  padding: 10px 0;
  border-radius: 5px;
}

.custom-menu-item:hover .custom-sub-menu {
  display: block;
}

.custom-sub-menu-item {
  padding: 8px 15px;
  font-size: 14px;
  color: #333;
}

.custom-sub-menu-item:hover {
  background-color: #f9f9f9;
  color: #fa4f09;
}

/* Mobile Search Bar */
.custom-mobile-search {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.custom-search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-mobile-search-input {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 14px;
  outline: none;
}

.custom-mobile-search-btn {
  background-color: #fa4f09;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-mobile-search-btn i {
  font-size: 16px;
}

/* Popper Search Results */
.custom-popper-container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  padding: 10px 0;
}

.custom-search-result-item {
  padding: 10px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.custom-search-result-item:hover {
  background-color: #f0f0f0;
}

/* Drawer Styling */
.header-mobile-drawer .MuiDrawer-paper {
  width: 100%; /* Fullscreen on mobile */
  max-width: 300px; /* Limit width */
  background-color: #f8f9fa; /* Light background */
  border-left: 1px solid #e5e5e5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Drawer Header */
.header-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333; /* Dark header */
  color: white;
  padding: 15px;
}

.header-drawer-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.header-drawer-close {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

/* Drawer Body */
.header-drawer-body {
  padding: 15px;
}

.header-drawer-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.header-drawer-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  text-align: left;
}

.header-drawer-btn:hover {
  background-color: #f0f0f0;
  border-radius: 5px;
}

/* Chevron Icon */
.header-drawer-btn i {
  font-size: 14px;
  color: #777;
}






/* Scroller Container */
.scroller-container {
  width: 100%;
  background-color: #b3e5fc; /* Light blue background */
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0; /* Vertical padding for spacing */
  box-sizing: border-box;
}

/* Scroller Content */
.scroller-content {
  display: flex;
  gap: 30px; /* Spacing between items */
  animation: scroll 15s linear infinite; /* Smooth scrolling animation */
  font-size: 14px; /* Exact font size */
  font-weight: bold; /* Exact font weight */
  font-family: Arial, sans-serif; /* Ensure the font matches */
  color: #000000; /* Black text color */
  text-transform: uppercase; /* All caps */
}

/* Scroll Animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .scroller-content {
    font-size: 15px; /* Slightly smaller font size for mobile */
    gap: 15px; /* Adjust spacing for mobile */
  }
}
/* Submenu styles - Force one column layout */
.header-drawer-submenu {
  display: flex;
  flex-direction: column; /* ✅ Stack items in one column */
  gap: 8px; /* ✅ Add spacing between submenu items */
  margin-top: 5px;
  padding-left: 20px;
}

.header-drawer-subitem {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 10px 14px; /* ✅ More padding for bigger look */
  font-size: 16px; /* ✅ Bigger font */
  font-weight: bold; /* ✅ Make text bold */
  cursor: pointer;
  color: #222; /* ✅ Darker color for emphasis */
  border-radius: 5px;
  transition: background 0.2s;
  display: flex;
  align-items: center; /* ✅ Align icon and text */
  gap: 10px; /* ✅ Space between icon and text */
}

.header-drawer-subitem:hover {
  background-color: #f4f4f4;
}











