* {
	margin            : 0;
	padding           : 0;
	-webkit-box-sizing: border-box;
	box-sizing        : border-box;
	outline           : none;
}

body {
	margin        : 0;
	padding       : 0;
	font-family   : var(--inter);
	color         : var(--white);
	line-height   : 28px;
	font-weight   : 400;
	text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family   : var(--poppins);
	font-weight   : 600;
	letter-spacing: -0.5px;
}


span {
	display: inline-block;
	color  : inherit;
}

del {
	color: #727272;
}

a {
	text-decoration: none;
	display        : inline-block;
	color          : var(--heading-color);
	transition     : var(--transition);
	cursor         : pointer !important;
}

button,
.btn {
	cursor: pointer;
}

a:hover {
	color: var(--heading-color);
}

ul {
	margin         : 0;
	padding        : 0;
	text-decoration: none;
}

li {
	list-style: none;
}

button {
	border          : none;
	background-color: transparent;
}

input {
	border : none;
	outline: none;
}

::placeholder {
	color: var(--span);
}

::selection {
	color           : var(--white);
	background-color: var(--primary-color);
}

// typography area end here ***

// Custom class area start here ***
// Margin Top ***
@for $i from 1 through 40 {
	.mt-#{5 * $i} {
		margin-top: 5px * $i;
	}
}

/*-- Margin Bottom --*/
@for $i from 1 through 40 {
	.mb-#{5 * $i} {
		margin-bottom: 5px * $i;
	}
}

// Margin Left ***
@for $i from 1 through 40 {
	.ml-#{5 * $i} {
		margin-left: 5px * $i;
	}
}

// Margin Right***
@for $i from 1 through 40 {
	.mr-#{5 * $i} {
		margin-right: 5px * $i;
	}
}

// Padding Top ***
@for $i from 1 through 40 {
	.pt-#{5 * $i} {
		padding-top: 5px * $i;
	}
}

// Padding Bottom ***
@for $i from 1 through 40 {
	.pb-#{5 * $i} {
		padding-bottom: 5px * $i;
	}
}

//  Padding Left ***
@for $i from 1 through 40 {
	.pl-#{5 * $i} {
		padding-left: 5px * $i;
	}
}

// Padding Right ***
@for $i from 1 through 40 {
	.pr-#{5 * $i} {
		padding-right: 5px * $i;
	}
}

.bor {
	border: 1px solid var(--border);
}

.bor-top {
	border-top: 1px solid var(--border);
}

.bor-left {
	border-left: 1px solid var(--border);
}

.bor-bottom {
	border-bottom: 1px solid var(--border);
}

.bor-right {
	border-right: 1px solid var(--border);
}

.border-none {
	border: none !important;
}

.text-justify {
	// text-align: justify;
}

.image {
	img {
		width: 100%;
	}
}

.primary-color {
	color: var(--primary-color);
}

.footer-color {
	color: var(--footer-color);
}

.primary-hover {
	transition: var(--transition);

	&:hover {
		color: var(--primary-color);
	}
}

.primary-bg {
	background-color: var(--primary-color);
}

.secondary-bg {
	background-color: var(--secondary-color);
}

.heading-color {
	color: var(--heading-color);
}

.sub-bg {
	background-color: var(--sub-bg);
}

.sm-font {
	font-size: 14px;
}

.bg-image {
	background-position: center;
	background-repeat  : no-repeat;
	background-size    : cover;
}

.overlay {
	// only use for extend
	position: relative;

	&::before {
		content : "";
		position: absolute;
		top     : 0;
		left    : 0;
		right   : 0;
		bottom  : 0;
	}
}

.radius-10 {
	border-radius: 10px;
}

.fw-600 {
	font-weight: 600;
}

.lightPara-color {
	color: #d2d2d2;
}

// Custom class area end here ***

// Star area start here ***
.star {
	i {
		color    : #ed8a19;
		font-size: 14px;
	}
}

// Star area end here ***


.round-100 {
	border-radius: 100%;
}

.text-sm {
	font-size: 14px !important;
}

.text-narrow-md {
	letter-spacing: -.3px !important;
}

.text-narrow-normal {
	letter-spacing: normal !important;
}

.cursror-pointer {
	cursor: pointer !important;
}


// MUI Custom Typo
.css-1sl1ukt-MuiChip-root,
.css-1cprn2g-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-1h4bzp1-MuiButtonBase-root-MuiMenuItem-root,
.css-8voplp-MuiFormLabel-root-MuiInputLabel-root,
.css-j882ge-MuiInputBase-root-MuiOutlinedInput-root,
.css-m0kdz1-MuiButtonBase-root-MuiButton-root,
.css-10yf2i8-MuiInputBase-root-MuiInput-root,
.css-1edfpdg-MuiTypography-root,
.css-1o0kqxp-MuiInputBase-root-MuiOutlinedInput-root,
.css-1q964xs-MuiFormLabel-root-MuiInputLabel-root,
.css-rxaxkm-MuiPaper-root-MuiAlert-root,
.css-1hv8oq8-MuiStepLabel-label,
.css-10ukr6t-MuiTableCell-root,
.css-1ojhcqg-MuiTableCell-root,
table,
tr,
td,
th {
	font-family: var(--inter) !important;
}


button,
.css-1eh2dyd-MuiButtonBase-root-MuiButton-root,
.css-1g8ipf2-MuiButtonBase-root-MuiButton-root {
	font-family: var(--poppins) !important;
	font-weight: 500 !important;
	box-shadow : none !important;
}

.css-8iab9z-MuiButtonBase-root-MuiButton-root {
	color: var(--primary-color) !important;
}

.btn-primary {
	background-color: var(--primary-color) !important;
	color           : var(--pure-white) !important;
}

.btn-outline-primary {
	border          : 1px solid var(--primary-color) !important;
	color           : var(--primary-color) !important;
	background-color: transparent !important;

	&:hover {
		background-color: var(--primary-color) !important;
		color           : var(--pure-white) !important;
	}
}

.btn-dark {
	color           : var(--primary-color) !important;
	background-color: var(--white) !important;

	&:hover {
		background-color: var(--primary-color) !important;
		color           : var(--pure-white) !important;
	}
}