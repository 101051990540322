// Banner one area start here ***
.banner {
	&-area {
		padding-top: 350px;
	}

	&__content {
		position : absolute;
		top      : 50%;
		left     : 40px;
		transform: translateY(-50%);

		h5 {
			margin-bottom: 20px;
		}
	}

	&__item {
		position: relative;
	}
}

// Banner one area end here ***

// Banner five area start here ***
.banner-two {
	overflow: hidden;
	position: relative;

	.hero-banner {
		width     : 100%;
		height    : 50%;
		object-fit: cover;
	}
}

.banner-two-light {
	.banner-two__content {
		h4 {
			color: var(--black);
		}

		h1 {
			color: var(--black);
		}

		p {
			color: var(--paragraph-light);
		}

		.banner-two__info {
			span {
				color: var(--paragraph-light);
			}

			h3 {
				color: var(--black);
			}
		}
	}
}

// Banner five area end here ***

// Page banner area start here ***
.page-banner {
	text-align: center;

	.breadcrumb-list {

		a,
		span {
			margin-right: 6px;
			color       : var(--pure-white);
			font-size   : 14px;
			font-weight : 600;
			cursor      : pointer;

			&:hover {
				color: var(--primary-color);
			}
		}
	}
}

.breadcrumb-list {

	a,
	span {
		margin-right: 10px;
		color       : var(--white);
		font-size   : 14px;
		font-weight : 500;
		cursor      : pointer;

		&:hover {
			color: var(--primary-color);
		}
	}
}

// Page banner area end here ***

// Ad banner area start here ***
.ad-banner {
	&-area {
		overflow: hidden;
	}

	&__content {
		position: relative;

		p {
			font-size  : 20px;
			font-weight: 600;
		}
	}

	&__content.left {
		margin-left: 25%;
	}

	&__item {
		position: relative;
		overflow: hidden;

		.bg-image {
			position  : absolute;
			top       : 0;
			left      : 0;
			width     : 100%;
			height    : 100%;
			transition: var(--transition);
		}

		&:hover {
			.bg-image {
				transform: scale(1.1);
			}
		}
	}
}

// Ad banner area end here ***