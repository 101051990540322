.login-area {
    background-color: #f8f9fa;
  }
  
  .login-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-box h3 {
    font-size: 1.5rem;
    color: #333;
  }
  
  .form-control {
    height: 45px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .is-invalid {
    border-color: #dc3545;
  }
  
  .invalid-feedback {
    font-size: 0.875rem;
    color: #dc3545;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
    font-size: 1rem;
    border-radius: 8px;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .text-secondary {
    color: #6c757d;
  }
  
  .text-primary {
    color: #007bff;
  }
  
  .text-primary:hover {
    text-decoration: underline;
  }
  