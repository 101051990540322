@media screen and (max-width : 1000px){
    .product-page-filter {
        display : none
    }
    .product-display-page-filter{
        margin-left: 20px;
    }
    
}

/* Main Menu Container */
.product-filter-menu {
    background-color: #f7f8fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Accordion Parent Items */
  .menu-accordion {
    border: 1px solid #e2e6ea;
    border-radius: 6px;
  }
  
  .menu-accordion-item {
    background-color: #fff;
    margin-bottom: 10px;
  }
  
  .menu-accordion-header {
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #f1f1f1;
  }
  
  .menu-icon {
    font-size: 20px;
    color: #4a90e2;
    margin-right: 10px;
  }
  
  .menu-accordion-button {
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .menu-arrow-icon {
    font-size: 14px;
    color: #888;
    transition: transform 0.3s ease;
  }
  
  .menu-accordion-button.collapsed .menu-arrow-icon {
    transform: rotate(-90deg);
  }
  
  /* Submenu Styling */
  .menu-accordion-body {
    padding: 10px 16px;
    background-color: #f9fafb;
  }
  
  .submenu-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .submenu-item {
    padding: 8px 0;
    font-size: 14px;
    color: #555;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .submenu-item:hover {
    color: #4a90e2;
  }
  
  .menu-submenu-icon {
    font-size: 14px;
    color: #4a90e2;
    margin-right: 10px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .product-filter-menu {
      padding: 15px;
      border-radius: 6px;
    }
  
    .menu-accordion-header {
      font-size: 15px;
      padding: 10px 14px;
    }
  
    .menu-accordion-body {
      padding: 8px 14px;
    }
  
    .submenu-item {
      font-size: 13px;
    }
  }

  /* Hide the menu on smaller screens */
.product-filter-menu {
    display: none;
  }
  
  @media (min-width: 992px) {
    .product-filter-menu {
      display: block;
    }
  }

  
  